<template>
  <div class="w-full p-4">

    <vs-row class="mb-2">
      <vs-col class="w-full">
      <ComplexEditor
        v-bind:editor_data.sync="commentData"
        :placeholder="$t('escreva-o-seu-comentario-aqui')"
      ></ComplexEditor>
      </vs-col>
    </vs-row>

    <vs-row class="mb-5">
      <vs-col class="w-full">
        <vs-button
          class="float-right"
          color="primary"
          @click="comment"
          :disabled="!commentData"
        >
          {{ $t('comentar') }}
        </vs-button>

      </vs-col>
    </vs-row>

    <h5 v-if="comments" class="mt-2 mb-4 border-bottom">{{commentAmount}}</h5>

    <vs-row v-if="!isEmpty(comments) && comments.length > 0">
      <vs-col>
      <VxTimeline
        :data="comments"
        :html="true">
      </VxTimeline>
      </vs-col>
    </vs-row>
    <vs-row v-else>
      <vs-col class="flex items-center justify-center">
        {{ $t('ainda-nao-ha-comentarios-para-esta-questao') }}
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import ComplexEditor from '@/views/apps/questionnaires/elements/ComplexEditor'
import VxTimeline from '@/components/timeline/VxTimeline'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
export default {

  props: {
    questionUUID : {
      default: null,
      type: Number
    },
    answerId : {
      default: null,
      type: Number
    }
  },

  components: {
    VxTimeline,
    ComplexEditor
  },

  data: () => ({
    questionnaireAnswerService: null,
    commentData: null,
    comments: null,
    mockData: function () {
      return [{title : this.$t('comentario-teste'), time: this.$t('2h-atras'), desc: this.$t('less-than-h4-greater-than-este-e-o-conteudo-com-html-less-than-h4-greater-than'), icon:'UserIcon', color:'primary'}]
    }
  }),

  methods: {
    getData(answerid, questionuuid) {
      this.answerId = answerid
      this.questionUUID = questionuuid
      this.comments = null
      this.$vs.loading()
      this.questionnaireAnswerService.findCommentsByAnswer(answerid, questionuuid).then(
        data => {
          this.comments = this.formatComments(data)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    formatComments(comments) {
      return comments.map(
        (obj) => {
          return {
            title : obj.author.name,
            time : obj.created_at,
            desc: JSON.parse(obj.data).body,
            icon: 'UserIcon',
            color: 'primary'
          }
        }
      )
    },

    comment(data) {
      const date = (new Date(Date.now())).toJSON()
      this.$vs.loading()
      this.questionnaireAnswerService.comment(this.answerId, this.questionUUID, 
        JSON.stringify({
          body : this.commentData
        })
      ).then(
        data => {
          this.commentData = null
          this.comments = this.formatComments(data)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    destroyElement() {
      this.destroyChilds(this)
      this.$destroy()
    }
  },
  computed: {
    commentAmount() {
      return (this.comments.length > 0) ?  this.$t('this-comments-length-comentarios', [this.comments.length]) : this.$t('nenhum-comentario')
    }
  },
  created() {

    this.$root.$on('notification.answer.comment', () => {
      this.getData(this.answerId, this.questionUUID)
    })

  },
  beforeMount() {
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>